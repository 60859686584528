/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import CallIcon from '@mui/icons-material/Call';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FleetButton from './FleetButton';
import BrandName from './BrandName';
import { useUser } from '../hooks/user';
import { useCompany } from '../hooks/company';
import {
  createMenuItemsBlock1,
  createMenuItemsBlock2,
} from '../shared/menuItems';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9.5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9.5)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerItem = styled('div')(({ justifyContent, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent,
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function DesktopNavigator({ taxIdentificationHash }) {
  const location = useLocation();
  const theme = useTheme();
  const user = useUser();
  const company = useCompany();

  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [fleetOpen, setFleetOpen] = useState(true);

  const listItemButtonSx = useMemo(
    () => ({
      minHeight: 48,
      justifyContent: sideBarOpen ? 'initial' : 'center',
      px: 2.5,
    }),
    [sideBarOpen]
  );

  const listItemIconSx = useMemo(
    () => ({
      minWidth: 0,
      mr: sideBarOpen ? 3 : 0,
      justifyContent: 'center',
      color: theme.palette.primary.main,
    }),
    [sideBarOpen]
  );

  const menuItemsBlock1 = useMemo(
    () => createMenuItemsBlock1(taxIdentificationHash),
    [taxIdentificationHash]
  );

  const menuItemsBlock2 = useMemo(
    () => createMenuItemsBlock2(taxIdentificationHash),
    [taxIdentificationHash]
  );

  const handleDrawerOpen = () => {
    setSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setSideBarOpen(false);
    setFleetOpen(false);
  };

  const toggleSettings = () => {
    setFleetOpen(!fleetOpen);
  };

  return (
    <Drawer variant='permanent' open={sideBarOpen}>
      <Box>
        <DrawerItem justifyContent='center'>
          {sideBarOpen && <BrandName />}
        </DrawerItem>
        <Divider />
        <List>
          {menuItemsBlock1.map(({ label, to, icon }) => (
            <ListItem key={label} sx={{ px: 0, py: 0 }}>
              <ListItemButton
                component={Link}
                to={to}
                sx={listItemButtonSx}
                selected={to === location.pathname}
              >
                <ListItemIcon sx={listItemIconSx}>{icon}</ListItemIcon>
                {sideBarOpen ? <ListItemText primary={label} /> : null}
              </ListItemButton>
            </ListItem>
          ))}

          <ListItemButton sx={listItemButtonSx} onClick={toggleSettings}>
            <ListItemIcon sx={listItemIconSx}>
              <DirectionsCarIcon />
            </ListItemIcon>
            {sideBarOpen ? (
              <ListItemText
                primary='Moja Flota'
                sx={{
                  opacity: sideBarOpen ? 1 : 0,
                  color: theme.palette.primary.main,
                }}
              />
            ) : null}
            {sideBarOpen ? (
              fleetOpen ? (
                <ExpandLessIcon sx={{ color: theme.palette.primary.main }} />
              ) : (
                <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
              )
            ) : null}
          </ListItemButton>
          <Collapse in={fleetOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                component={Link}
                to={`/firma/${taxIdentificationHash}/flota/pojazdy`}
                sx={{ pl: 6 }}
              >
                <ListItemIcon
                  sx={{
                    color: theme.palette.primary.main,
                    minWidth: 40,
                  }}
                >
                  <OnlinePredictionIcon />
                </ListItemIcon>
                <ListItemText primary='Pojazdy' />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={`/firma/${taxIdentificationHash}/flota/ustawienia`}
                sx={{ pl: 6 }}
              >
                <ListItemIcon
                  sx={{
                    color: theme.palette.primary.main,
                    minWidth: 40,
                  }}
                >
                  <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary='Ustawienia' />
              </ListItemButton>
            </List>
          </Collapse>

          {menuItemsBlock2.map(({ label, to, icon }) => (
            <ListItem key={label} sx={{ px: 0, py: 0 }}>
              <ListItemButton
                component={Link}
                to={to}
                sx={listItemButtonSx}
                selected={to === location.pathname}
              >
                <ListItemIcon sx={listItemIconSx}>{icon}</ListItemIcon>
                {sideBarOpen ? <ListItemText primary={label} /> : null}
              </ListItemButton>
            </ListItem>
          ))}

          <ListItem sx={{ px: 0, py: 0 }}>
            <ListItemButton
              component={Link}
              to={`/firma/${taxIdentificationHash}/kontakt`}
              sx={listItemButtonSx}
            >
              <ListItemIcon sx={listItemIconSx}>
                <CallIcon />
              </ListItemIcon>
              {sideBarOpen ? <ListItemText primary='Kontakt' /> : null}
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box style={{ marginTop: 'auto' }}>
        {sideBarOpen && !user && (
          <DrawerItem sx={{ flexDirection: 'column' }} justifyContent='center'>
            <Typography variant='caption' sx={{ mb: 1 }}>
              Przeglądasz jako gość
            </Typography>
            <FleetButton
              size='small'
              variant='outlined'
              style={{ minWidth: 24 }}
              onClick={() => {
                window.location.href = `/firma/${taxIdentificationHash}/zaloguj-sie`;
              }}
            >
              Zaloguj się
            </FleetButton>
          </DrawerItem>
        )}
        {sideBarOpen && user && (
          <DrawerItem sx={{ flexDirection: 'column' }} justifyContent='center'>
            <Typography variant='caption'>
              Przeglądasz jako <strong>{user.firstName}</strong>
            </Typography>
            <Typography
              variant='caption'
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                whiteSpace: 'normal',
                textAlign: 'center',
              }}
            >
              {company.name}
            </Typography>
            <Typography variant='caption' sx={{ mb: 1 }}>
              PL{company.taxIdentificationNumber}
            </Typography>
            <FleetButton
              size='small'
              variant='outlined'
              style={{ minWidth: 24 }}
              onClick={async () => {
                await user.logout();
              }}
            >
              Wyloguj się
            </FleetButton>
          </DrawerItem>
        )}
        <DrawerItem justifyContent={sideBarOpen ? 'flex-end' : 'center'}>
          {sideBarOpen ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <ChevronRightIcon />
            </IconButton>
          )}
        </DrawerItem>
      </Box>
    </Drawer>
  );
}
