/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TableContainer from '@mui/material/TableContainer';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Plate from '../../../components/Plate';
import ViewHeader from '../../../components/ViewHeader';
import FleetButton from '../../../components/FleetButton';
import { useDevice } from '../../../hooks/device';
import { useRepository } from '../../../hooks/api';
import { useCompany } from '../../../hooks/company';
import { useSnackbar } from '../../../hooks/snackbar';
import { displayPrice, displayDiscount } from '../../../shared/numbers';
import { vignetteLabels } from '../../../shared/labels';
import {
  getCellStyleForMobile,
  lastRowWithoutBottomBorder,
} from '../../../shared/styles';
import { COUNTRY_CODE_TO_COUNTRY_LABEL } from '../../../shared/constants';
import CartRowEmpty from './CartRowEmpty';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function CartTable({ cart, onCartRefresh }) {
  const company = useCompany();
  const repository = useRepository();
  const openSnackbar = useSnackbar();
  const navigate = useNavigate();
  const { isMobile, isBrowser } = useDevice();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [createOrderSubmitted, setCreateOrderSubmitted] = useState(false);

  const isCartEmpty = useMemo(() => {
    return cart.cartItems.length === 0;
  }, [cart]);

  const { billedMonthly } = company;

  const getCellStyle = useCallback(
    (style = {}) => ({
      ...getCellStyleForMobile(isBrowser),
      ...style,
    }),
    []
  );

  const handleRemoveFromCart = useCallback(
    async (cartItemId) => {
      try {
        const response = await repository.company.removeCartItem(
          company.taxIdentificationHash,
          cartItemId
        );

        const { status } = response;
        if (status === 200) {
          openSnackbar('Usunięto winietę z koszyka', 'success');
        } else {
          openSnackbar('Nie usunięto winiety z koszyka', 'error');
        }
      } catch (e) {
        openSnackbar('Nie usunięto winiety z koszyka', 'error');
      }

      onCartRefresh();
    },
    [cart]
  );

  const handleOrderAndPayDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleOrderAndPayConfirmDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleCreateOrder = async () => {
    setCreateOrderSubmitted(true);

    try {
      const response = await repository.company.createOrder(
        company.taxIdentificationHash
      );

      if (billedMonthly) {
        openSnackbar(
          'Zamówienie złożone pomyślnie, przekazano je do realizacji...',
          'success'
        );

        setTimeout(() => {
          navigate(`../../firma/${company.taxIdentificationHash}/zamowienia`);
        }, 1500);
      } else {
        openSnackbar(
          'Zamówienie złożone pomyślnie, nastąpi przekierowanie do płatności...',
          'success'
        );

        setTimeout(() => {
          const {
            data: { gatewayRedirectUri },
          } = response;

          window.location.href = gatewayRedirectUri;
        }, 1500);
      }
    } catch (e) {
      openSnackbar('Nie udało się złożyć zamówienia', 'error');
      onCartRefresh();
    }

    setIsDialogOpen(false);
  };

  const renderTableBody = () => {
    if (!isCartEmpty) {
      const { cartItems } = cart;
      return cartItems.map((cartItem) => {
        const [year, month, day] = cartItem.vignetteStartDate.split('-');
        return (
          <TableRow key={cartItem.id} sx={{ ...lastRowWithoutBottomBorder }}>
            <TableCell style={getCellStyle()}>
              <Plate
                country={
                  COUNTRY_CODE_TO_COUNTRY_LABEL[cartItem.vehicleCountryCode]
                }
                number={cartItem.vehicleLicensePlate}
              />
            </TableCell>
            <TableCell style={getCellStyle()}>
              {vignetteLabels[cartItem.vignetteCode]}
            </TableCell>
            <TableCell style={getCellStyle()}>
              {`${day}-${month}-${year}`}
            </TableCell>
            <TableCell style={getCellStyle()}>
              {`${displayPrice(cartItem.product.grossPrice)}`}
            </TableCell>
            <TableCell style={getCellStyle()}>
              <Button
                size='small'
                variant='outlined'
                sx={{
                  padding: 0,
                  minWidth: 30,
                  minHeight: 30,
                  borderRadius: '3px',
                }}
                onClick={() => handleRemoveFromCart(cartItem.id)}
              >
                <DeleteOutlineIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    }

    return <CartRowEmpty />;
  };

  const renderSummary = () => {
    if (!isCartEmpty) {
      return (
        <>
          <TableRow>
            <TableCell
              colSpan={3}
              style={{
                ...getCellStyle(),
                textAlign: 'right',
              }}
            >
              Razem
            </TableCell>
            <TableCell style={getCellStyle()}>
              {`${displayPrice(cart.totalValue)}`}
            </TableCell>
            <TableCell style={getCellStyle()} />
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={3}
              style={{
                ...getCellStyle(),
                textAlign: 'right',
              }}
            >
              Rabat {displayDiscount(cart.discount)}
            </TableCell>
            <TableCell style={getCellStyle()}>
              {`-${displayPrice(cart.discountValue)}`}
            </TableCell>
            <TableCell style={getCellStyle()} />
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={3}
              style={{
                ...getCellStyle(),
                textAlign: 'right',
              }}
            >
              <strong>Razem po rabacie</strong>
            </TableCell>
            <TableCell style={getCellStyle()}>
              <strong>{`${displayPrice(cart.discountedValue)}`}</strong>
            </TableCell>
            <TableCell style={getCellStyle()} />
          </TableRow>
        </>
      );
    }

    return null;
  };

  const areAllVignettesActive = useMemo(() => {
    return cart.cartItems.every((cartItem) => cartItem.product.isActive);
  }, [cart]);

  return (
    <>
      <List style={{ flexGrow: '1', padding: 0 }}>
        <ViewHeader>
          <Typography
            variant='h6'
            style={{
              fontSize: 16,
            }}
          >
            Koszyk
          </Typography>
        </ViewHeader>
        {isCartEmpty && (
          <Alert severity='info'>
            Aby dodać winiety do koszyka, przejdź do sekcji Moja Flota, a
            następnie Status.
          </Alert>
        )}
        {!isCartEmpty && billedMonthly && (
          <Alert severity='info'>
            Twoja flota jest rozliczana jedną, zbiorczą płatnością na miesiąc.
            To znaczy, że wystawimy Ci jedną fakturę na koniec miesiąca za
            wszystkie zakupione winiety.{' '}
            <strong>
              Po złożeniu zamówienia nastąpi jego niezwłoczone przyjęcie do
              realizacji. Prosimy o sprawdzenie poprawności wprowadzonych
              danych.
            </strong>
          </Alert>
        )}
        <Card variant='outlined' sx={{ mt: 1, mb: 1 }}>
          <Box
            sx={{
              ...(isMobile
                ? {
                    pl: 0,
                    pr: 0,
                    pb: 0,
                  }
                : {
                    pl: 3,
                    pr: 3,
                    pb: 3,
                  }),
            }}
          >
            <TableContainer>
              <Table style={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '30%' }}>Pojazd</TableCell>
                    <TableCell>Winieta</TableCell>
                    <TableCell>Ważna od</TableCell>
                    <TableCell>Cena</TableCell>
                    <TableCell>Akcje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableBody()}
                  {renderSummary()}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {!isCartEmpty && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                ...(isMobile
                  ? {
                      pt: 1,
                      pl: 1,
                      pr: 1,
                      pb: 1,
                    }
                  : {
                      pl: 3,
                      pr: 3,
                      pb: 3,
                    }),
              }}
            >
              <FleetButton
                variant='contained'
                disableElevation
                disabled={!areAllVignettesActive || createOrderSubmitted}
                onClick={handleOrderAndPayDialogOpen}
              >
                Zamawiam
              </FleetButton>
            </Box>
          )}
        </Card>
      </List>
      <Dialog
        keepMounted
        open={isDialogOpen}
        TransitionComponent={Transition}
        onClose={handleOrderAndPayConfirmDialogClose}
        aria-describedby='confirm-dialog'
      >
        <DialogTitle>
          {billedMonthly
            ? 'Potwierdzenie złożenia zamówienia'
            : 'Potwierdzenie złożenia i opłacenia zamówienia'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-dialog'>
            {billedMonthly ? (
              <>
                Nastąpi złożenie i przekazanie zamówienia do realizacji. Czy
                kontynuować?
              </>
            ) : (
              <>
                Nastąpi złożenie zamówienia i przekierowanie do bramki
                płatniczej, aby je opłacić. Czy kontynuować?
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FleetButton onClick={handleOrderAndPayConfirmDialogClose}>
            Anuluj
          </FleetButton>
          <FleetButton
            disabled={createOrderSubmitted}
            onClick={handleCreateOrder}
          >
            {billedMonthly ? (
              <>Złóż zamówienie</>
            ) : (
              <>Złoż zamówienie i opłać</>
            )}
          </FleetButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
