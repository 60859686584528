/* eslint-disable react/prop-types */
import React from 'react';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDevice } from '../../../hooks/device';
import { useStatusStateValue } from '../../../contexts/statusStateContext';
import Flag from '../../../components/Flag/Flag';
import TableBox from '../../../components/TableBox';
import ViewHeader from '../../../components/ViewHeader';
import FleetTooltip from '../../../components/FleetTooltip';
import StatusRow from './StatusRow';
import StatusRowEmpty from './StatusRowEmpty';
import AddVignetteDialog from './AddVignetteDialog';
import { VEHICLES_TABLE_COLUMN_WIDTHS } from '../../../shared/constants';

export default function StatusTable({ onVehiclesRefresh }) {
  const { isMobile, isBrowser } = useDevice();

  const [state, dispatch] = useStatusStateValue();

  const handleTextFilterChange = (newTextFilter) => {
    dispatch({
      type: 'SET_REGISTRATION_NUMBER_FILTER',
      payload: newTextFilter,
    });
    dispatch({ type: 'SET_FILTERED_VEHICLES' });
  };

  const handleTextFilterReset = () => {
    dispatch({ type: 'SET_REGISTRATION_NUMBER_FILTER', payload: '' });
    dispatch({ type: 'SET_FILTERED_VEHICLES' });
  };

  const handleCountriesFilterChange = (countryCode) => {
    dispatch({ type: 'SET_COUNTRIES_FILTER', payload: countryCode });
    dispatch({ type: 'SET_FILTERED_VEHICLES' });
  };

  const handleCountriesFilterReset = () => {
    dispatch({ type: 'SET_DEFAULT_COUNTRIES_FILTER' });
    dispatch({ type: 'SET_FILTERED_VEHICLES' });
  };

  const handleAddVignetteDialogOpen = (vehicle) => {
    dispatch({ type: 'SET_VEHICLE', payload: vehicle });

    dispatch({ type: 'SET_AVAILABLE_COUNTRIES' });
    dispatch({ type: 'SET_AVAILABLE_VIGNETTES' });
    dispatch({ type: 'SET_FIRST_AVAILABLE_VIGNETTE' });
    dispatch({
      type: 'SET_VIGNETTE_PARAMS',
      payload: {
        startDate: new Date(),
      },
    });

    dispatch({ type: 'SET_IS_ADD_VIGNETTE_DIALOG_OPEN', payload: true });
  };

  const handleAddVignetteDialogClose = () => {
    dispatch({ type: 'SET_IS_ADD_VIGNETTE_DIALOG_OPEN', payload: false });
    dispatch({ type: 'SET_VEHICLE', payload: null });
  };

  const renderTableBody = () => {
    const vehicles = state.filteredVehicles;
    if (vehicles.length > 0) {
      return vehicles.map((vehicle, index) => (
        <StatusRow
          key={`${vehicle.registrationCountry}${vehicle.registrationNumber}`}
          vehicle={vehicle}
          isLastVehicle={index === vehicles.length - 1}
          onAddVignette={handleAddVignetteDialogOpen}
        />
      ));
    }

    return <StatusRowEmpty />;
  };

  const { registrationNumberFilter, countriesFilter } = state;

  return (
    <>
      <List style={{ flexGrow: '1', padding: 0 }}>
        <ViewHeader>
          <Typography
            variant='h6'
            style={{
              fontSize: 16,
            }}
          >
            Moja Flota - pojazdy
          </Typography>
        </ViewHeader>
        <Alert severity='info'>
          Tutaj znajdują się posortowane alfabetycznie wszystkie pojazdy Twojej
          floty wraz z informacjami o wykupionych winietach.{' '}
          <strong>
            Możesz także wykupić nowe winiety, klikając na przycisk + w kolumnie
            Aktywne winiety.
          </strong>
        </Alert>
        <Card variant='outlined' sx={{ mt: 1, mb: 1 }}>
          <TableBox>
            <TableContainer>
              <Table style={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_1 }}
                    />
                    <TableCell
                      style={{ width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_2 }}
                    >
                      Pojazd
                    </TableCell>
                    <TableCell>Aktywne winiety</TableCell>
                    {isBrowser ? <TableCell /> : null}
                    <TableCell>Najszybciej wygasająca</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_1 }}
                    />
                    <TableCell
                      style={{ width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_2 }}
                    >
                      <div style={{ display: 'flex', gap: 5 }}>
                        <TextField
                          style={{ maxWidth: 140 }}
                          label='Filtruj'
                          variant='outlined'
                          size='small'
                          value={registrationNumberFilter}
                          onChange={(e) =>
                            handleTextFilterChange(e.target.value)
                          }
                        />
                        {isBrowser && (
                          <FleetTooltip
                            placement='top'
                            title='Usuń filtr po numerze rejestracyjnym'
                          >
                            <div>
                              <Button
                                size='small'
                                variant='outlined'
                                sx={{
                                  padding: 0,
                                  minWidth: 40,
                                  minHeight: 40,
                                  borderRadius: '3px',
                                }}
                                aria-haspopup='true'
                                disabled={registrationNumberFilter.length === 0}
                                onClick={handleTextFilterReset}
                              >
                                <DeleteForeverIcon />
                              </Button>
                            </div>
                          </FleetTooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell colSpan={isMobile ? 1 : 2}>
                      <div
                        style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}
                      >
                        {Object.entries(countriesFilter).map(
                          ([countryCode, isFilterActive]) => (
                            <Flag
                              key={countryCode}
                              style={{
                                cursor: 'pointer',
                                opacity: isFilterActive ? 1 : 0.2,
                              }}
                              countryCode={countryCode}
                              onClick={() =>
                                handleCountriesFilterChange(countryCode)
                              }
                            />
                          )
                        )}
                        {isBrowser && (
                          <FleetTooltip
                            placement='top'
                            title='Usuń filtr po aktywnych krajach'
                          >
                            <div>
                              <Button
                                size='small'
                                variant='outlined'
                                sx={{
                                  padding: 0,
                                  minWidth: 36,
                                  minHeight: 27,
                                  borderRadius: '3px',
                                }}
                                aria-haspopup='true'
                                disabled={
                                  Object.entries(countriesFilter).filter(
                                    ([, isFilterActive]) => isFilterActive
                                  ).length === 0
                                }
                                onClick={handleCountriesFilterReset}
                              >
                                <DeleteForeverIcon />
                              </Button>
                            </div>
                          </FleetTooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </TableContainer>
          </TableBox>
        </Card>
      </List>
      {state.isAddVignetteDialogOpen && (
        <AddVignetteDialog
          onClose={handleAddVignetteDialogClose}
          onVehiclesRefresh={onVehiclesRefresh}
        />
      )}
    </>
  );
}
