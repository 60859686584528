/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import BrandName from './BrandName';
import FleetButton from './FleetButton';
import { useUser } from '../hooks/user';
import { useCompany } from '../hooks/company';
import {
  createMenuItemsBlock1,
  createMenuItemsBlock2,
} from '../shared/menuItems';

const drawerWidth = 240;

const DrawerItem = styled('div')(({ justifyContent, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent,
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function MobileNavigator({ taxIdentificationHash }) {
  const location = useLocation();
  const user = useUser();
  const company = useCompany();

  const [mobileOpen, setMobileOpen] = useState(false);

  const menuItemsBlock1 = useMemo(
    () => createMenuItemsBlock1(taxIdentificationHash),
    [taxIdentificationHash]
  );

  const menuItemsBlock2 = useMemo(
    () => createMenuItemsBlock2(taxIdentificationHash),
    [taxIdentificationHash]
  );

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <>
      <AppBar component='nav'>
        <Toolbar
          sx={{
            justifyContent: { xs: 'end' },
            paddingLeft: { xs: 0 },
            paddingRight: { xs: 0 },
          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <DrawerItem justifyContent='center'>
              <BrandName />
            </DrawerItem>
            <Divider />
            <List>
              {menuItemsBlock1.map(({ label, to }) => (
                <ListItem key={label} disablePadding>
                  <ListItemButton
                    sx={{ textAlign: 'center' }}
                    component={Link}
                    to={to}
                    selected={to === location.pathname}
                  >
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: 'center' }}
                  component={Link}
                  to={`/firma/${taxIdentificationHash}/flota/pojazdy`}
                  selected={
                    `/firma/${taxIdentificationHash}/flota/pojazdy` ===
                    location.pathname
                  }
                >
                  <ListItemText primary='Pojazdy floty' />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: 'center' }}
                  component={Link}
                  to={`/firma/${taxIdentificationHash}/flota/ustawienia`}
                  selected={
                    `/firma/${taxIdentificationHash}/flota/ustawienia` ===
                    location.pathname
                  }
                >
                  <ListItemText primary='Ustawienia floty' />
                </ListItemButton>
              </ListItem>

              {menuItemsBlock2.map(({ label, to }) => (
                <ListItem key={label} disablePadding>
                  <ListItemButton
                    sx={{ textAlign: 'center' }}
                    component={Link}
                    to={to}
                    selected={to === location.pathname}
                  >
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              ))}

              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: 'center' }}
                  component={Link}
                  to={`/firma/${taxIdentificationHash}/kontakt`}
                  selected={
                    `/firma/${taxIdentificationHash}/kontakt` ===
                    location.pathname
                  }
                >
                  <ListItemText primary='Kontakt' />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          <Box style={{ marginTop: 'auto', marginBottom: '1rem' }}>
            {!user && (
              <DrawerItem
                sx={{ flexDirection: 'column' }}
                justifyContent='center'
              >
                <Typography variant='caption' sx={{ mb: 1 }}>
                  Przeglądasz jako gość
                </Typography>
                <FleetButton
                  size='small'
                  variant='outlined'
                  style={{ minWidth: 24 }}
                  onClick={() => {
                    window.location.href = `/firma/${taxIdentificationHash}/zaloguj-sie`;
                  }}
                >
                  Zaloguj się
                </FleetButton>
              </DrawerItem>
            )}
            {user && (
              <DrawerItem
                sx={{ flexDirection: 'column' }}
                justifyContent='center'
              >
                <Typography variant='caption'>
                  Przeglądasz jako <strong>{user.firstName}</strong>
                </Typography>
                <Typography
                  variant='caption'
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    whiteSpace: 'normal',
                    textAlign: 'center',
                  }}
                >
                  {company.name}
                </Typography>
                <Typography variant='caption' sx={{ mb: 1 }}>
                  PL{company.taxIdentificationNumber}
                </Typography>
                <FleetButton
                  size='small'
                  variant='outlined'
                  style={{ minWidth: 24 }}
                  onClick={async () => {
                    await user.logout();
                  }}
                >
                  Wyloguj się
                </FleetButton>
              </DrawerItem>
            )}
          </Box>
        </Drawer>
      </Box>
    </>
  );
}
